import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Note: This is the first post written by Jessica Manley who works on creating the education which goes into our platform. She will be writing occasionally on topics relating to our education efforts.`}</em></p>
    <p>{`I am always looking for ways to better understand our customers. My quest for better understanding normally drives me to conduct impromptu Q&A sessions with complete strangers on the subway. Recently, I've noticed the mainstream media is starting to focus more attention on the unbanked and the financial services that support them.`}</p>
    <p>{`Following the recent Prepaid Expo in Las Vegas this past March, there was a flurry of media coverage surrounding prepaid cards and the underbanked. These three articles below caught my eye, in particular, as they provided different vantage points into alternative banking solutions and why each solution is such an appealing and practical option for the underbanked.`}</p>
    <p><strong parentName="p">{`1) Payday Loans: Emergency Credit Relief`}</strong></p>
    <p><a parentName="p" {...{
        "href": "http://www.linkedin.com/pub/ken-rees/1/1a3/ba"
      }}>{`Ken Rees`}</a>{`, the CEO of `}<a parentName="p" {...{
        "href": "http://www.thinkfinance.com/"
      }}>{`Think Finance`}</a>{`, recently wrote a piece in Fox News "`}<a parentName="p" {...{
        "href": "http://www.foxnews.com/opinion/2012/03/15/lets-not-give-under-banked-consumers-boot/#ixzz1sb4Gh1w0"
      }}>{`Let's not give Americans who need emergency cash 'The Boot'`}</a>{`"!`}</p>
    <p>{`The article recounts Ken's conversation with an unnamed state attorney general who banned payday loans. Ken points out that there are many times when individuals need access to cash and payday loans can provide that cash.`}</p>
    <p>{`I agree with that point of view. Consumers need to have options available to them. The trick, and what we believe at PayPerks, is to make sure that consumers fully understand the details of the options available to them. In the case of payday loans, this means consumers should be able to clearly understand the fees, interest rates and general terms of the loan -- something that is not true for many payday loans available to consumers now.`}</p>
    <p>{`There may be times when short term access to cash, even with a relatively expensive payday loan, can be a cheaper option that not paying a bill that has more punitive late fees or an option that allows a family to eat or buy a necessity.`}</p>
    <p>{`Ken said it best:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Let’s focus our efforts on expanding the financial options for under-banked consumers. This will likely involve innovative new products and new applications of technology and will need to be supported by a progressive regulatory environment.`}</p>
    </blockquote>
    <p><strong parentName="p">{`2) Check Cashing: Cash Made Easy`}</strong></p>
    <p>{`The New York Times, through its former partnership with `}<a parentName="p" {...{
        "href": "http://baycitizen.org"
      }}>{`The Bay Citizen`}</a>{`, recently published an article by Titania Kumeh titled "`}<a parentName="p" {...{
        "href": "http://www.nytimes.com/2012/03/18/us/programs-are-under-way-to-help-the-san-francisco-bay-areas-unbanked.html"
      }}>{`Unemployed Is Bad Enough; ‘Unbanked’ Can Be Worse`}</a>{`".`}</p>
    <p>{`The article highlights the growing number of unbanked in the Bay Area and how these individuals turn to check cashing institutions in order to meet their everyday banking needs. While check-cashing fees could total \\$1,000 a year and interest rates for loans are as high as 425 percent, this is often the only method of retrieving cash for those who have been excluded or refuse to participate in traditional banking.`}</p>
    <p>{`Why do consumers choose to pay these high fees? I think `}<a parentName="p" {...{
        "href": "http://newamerica.net/user/123"
      }}>{`Anne Stuhldreher's`}</a>{`, a senior policy fellow at `}<a parentName="p" {...{
        "href": "http://newamerica.net/"
      }}>{`New America Foundation`}</a>{`, comment at the end of article is spot on: “There’s a lot financial institutions can learn from check cashers,” she said. “They’re convenient. Some are open 24 hours. Their fees are too high, but they are transparent.”`}</p>
    <p>{`This is why PayPerks places so much effort on creating educational products that increase fee transparency to consumers and allows them to take full advantage of the conveniences of prepaid debit cards which often go unused.`}</p>
    <p><strong parentName="p">{`3) Prepaid Cards: the Substitute for Banking`}</strong></p>
    <p>{`Rourke O'Brien at the New America Foundation recently published a research paper on the financial lives of families on public assistance. You can read the brief `}<a parentName="p" {...{
        "href": "http://newamerica.net/publications/policy/we_dont_do_banks"
      }}>{`here`}</a>{` or download the full report in PDF `}<a parentName="p" {...{
        "href": "http://assets.newamerica.net/sites/newamerica.net/files/policydocs/RourkeMarch2012.pdf"
      }}>{`here`}</a>{`.`}</p>
    <p>{`The full report is an interesting read, but if you're short on time, here are a few key takeaways:`}</p>
    <ul>
      <li parentName="ul">{`Contrary to popular belief that low-income families would be better off utilizing traditional banking institutions, these individuals often choose to remain “unbanked” after having negative experiences with traditional institutions and find the alternatives to be much more straightforward.`}</li>
      <li parentName="ul">{`The primary reason that respondents left traditional banking institutions was because they found the fees to be exorbitant and unpredictable for the slightest mistake, and although alternatives often can come with fees, they are comprehensible and transparent.`}</li>
      <li parentName="ul">{`Those surveyed found prepaid cards to be easy to use, more secure than carrying around cash, and allowed for greater flexibility when managing their finances.`}</li>
      <li parentName="ul">{`Individual users felt they could be more autonomous when using prepaid cards and they did not have the same negative feelings toward the prepaid card issuers as they did towards traditional banking institutions.`}</li>
    </ul>
    <p>{`As prepaid cards become more widespread, PayPerks is committed to providing education that will allow users to reduce the fees associated with the prepaid card and make the most of their financial future.`}</p>
    <p><strong parentName="p">{`The Future of Banking for the Underbanked`}</strong></p>
    <p>{`There’s a reason these non-bank alternatives, such as payday loans and check cashing, have become so popular: they speak to the needs of the underbanked. It is clear that there are inadequacies in traditional institutions when it comes to serving the underbanked.`}</p>
    <p>{`While there are people who are addressing these shortcomings, there remains a discontinuity between the financial services provided and the education that goes hand-in-hand with these products. There’s still much needed in the way of financial education in order to ensure that the underbanked know their options and are able to make decisions that are the best for them.`}</p>
    <p>{`Government oversight and product innovation need to continue and PayPerks will be there to explain the new products to the unbanked step-by-step.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      